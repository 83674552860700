import domtoimage from 'dom-to-image'
import { saveAs } from 'file-saver'
import React from 'react'
import {
  Area,
  ComposedChart,
  Line,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../../tailwind.config'
const { theme } = resolveConfig(tailwindConfig)

const CustomXLabel = (props) => {
  const { x, y, payload } = props
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        fontSize={11}
        textAnchor="middle"
        fill="#666"
        transform="rotate(-15)"
      >
        {payload.value}
      </text>
    </g>
  )
}

const CustomYLabel = (props) => {
  const { x, y, payload, unit } = props
  return (
    <g>
      <text
        x={x}
        y={y}
        dy={5}
        dx={-15}
        fontSize={11}
        textAnchor="middle"
        fill="#666"
      >
        {payload.value}
        {unit}
      </text>
    </g>
  )
}

const CustomizedReferenceLineLabel = (props) => {
  const { date, viewBox } = props
  return (
    <g>
      <text
        x={viewBox.x}
        y={viewBox.y}
        dy={-5}
        // dx={-15}
        fontSize={11}
        textAnchor="middle"
        fill="#1F2937"
      >
        {date}
      </text>
    </g>
  )
}

const renderTooltip = ({ payload, param }) => {
  if (typeof payload === 'undefined') return null
  if (!payload) return null
  if (payload.length === 0) return null
  return payload.map((entry, i) => {
    if (i === 0) {
      return (
        <div
          key={i}
          className="flex flex-col px-4 py-3 rounded bg-gray-50 opacity-90"
        >
          <div>
            <span className="mr-1 text-xs font-semibold">Date:</span>
            <span className="text-xs">{entry.payload.dateDisplay}</span>
          </div>
          <div className="mt-2">
            <span className="mr-1 text-xs font-semibold">{param.name}:</span>
            <span className="text-xs">{entry.payload[param.key]}%</span>
          </div>
          <div className="mt-2">
            <span className="mr-1 text-xs font-semibold">Uncertainty:</span>
            <span className="text-xs">
              {entry.payload.confidenceInterval[0]}% to{' '}
              {entry.payload.confidenceInterval[1]}%
            </span>
          </div>
        </div>
      )
    } else {
      return null
    }
  })
}

export default function AscosporeMaturityGraph({ title, data, biofix1 }) {
  function downloadPNG() {
    domtoimage
      .toBlob(document.getElementById('AscosporeMaturityGraph'))
      .then((blob) => saveAs(blob, `${title} Chart.png`))
  }

  if (data === null) return null

  let reducedData = [...data]

  let startIdx = 0
  if (biofix1) {
    startIdx = data.findIndex((d) => d.dayOfYear === biofix1.dayOfYear)
  }
  const endIdx = data.findIndex((d) => d.ascosporeMaturity >= 100)
  if (startIdx > -1 && endIdx > -1) {
    reducedData = data.slice(startIdx - 3, endIdx)
  }

  const maturity95Reached = reducedData.find((d) => d.maturity95Reached)

  return (
    <div className="w-full" aria-hidden="true">
      <div className="flex items-center justify-between mb-6">
        <h2 className="inline-block text-lg font-semibold text-gray-600 sm:text-xl md:text-2xl">
          {title}
        </h2>

        <div className="flex items-center justify-center rounded-md">
          <button
            type="button"
            aria-label="download graph in png format"
            onClick={downloadPNG}
            className="inline-flex items-center p-2 text-sm font-bold leading-4 text-white transition duration-150 ease-in-out border border-transparent rounded-md sm:px-3 sm:py-2 bg-secondary-800 hover:bg-secondary-700 focus:outline-none focus:border-secondary-900 focus:shadow-outline-secondary active:bg-secondary-900"
          >
            <svg
              className="-ml-0.5 mr-2 h-4 w-4 text-white"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
            </svg>
            <span className="hidden mr-1 sm:inline-block">Download </span>{' '}
            <span className="text-white">PNG</span>
          </button>
        </div>
      </div>

      <div id="AscosporeMaturityGraph">
        <ResponsiveContainer
          width="100%"
          height={300}
          id="AscosporeMaturityGraph"
        >
          <ComposedChart
            data={reducedData}
            margin={{ top: 40, right: 30, left: -30, bottom: 40 }}
            className="bg-white border-b border-gray-200 shadow sm:rounded-lg"
          >
            <XAxis
              dataKey="dateDisplay"
              interval={'preserveStartEnd'}
              axisLine={true}
              tick={<CustomXLabel />}
            ></XAxis>
            <YAxis
              width={110}
              dataKey="ascosporeMaturity"
              // domain={[0, 100]}
              tick={<CustomYLabel />}
              label={{
                value: 'Ascospore Maturity (%)',
                angle: -90,
                position: 'center',
                offset: 0,
              }}
            />
            <Tooltip
              content={renderTooltip}
              param={{ name: 'Ascospore Maturity', key: 'ascosporeMaturity' }}
            />
            <Area
              dataKey="confidenceInterval"
              stroke={theme.colors.purple['200']}
              fill={theme.colors.purple['200']}
              // fillOpacity={0.7}
            />

            <Line
              type="monotone"
              dot={false}
              dataKey="ascosporeMaturity"
              stroke={theme.colors.purple['600']}
              strokeWidth={1.5}
            />

            {biofix1 && (
              <ReferenceLine
                x={biofix1.dateDisplay}
                stroke="#1F2937"
                strokeWidth={1}
                isFront={true}
                label={<CustomizedReferenceLineLabel date={'Green Tip'} />}
              />
            )}

            {maturity95Reached && (
              <ReferenceDot
                x={maturity95Reached.dateDisplay}
                y={95}
                stroke="none"
                r={3}
                fill={theme.colors.purple['700']}
                label={<CustomizedReferenceLineLabel date={'95%'} />}
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
