import {isFuture} from 'date-fns'
import React from 'react'
import {CSVLink} from 'react-csv'

function dryWet(event) {
  return event === 'dry'
    ? `text-yellow-900 bg-yellow-300`
    : `text-white bg-blue-500`
}

export default function WetDryLogTable({tableData, csvData, selectedDate}) {
  if (!tableData) return null

  const filtered = tableData.filter(
    event => event.startDateMs < selectedDate.ms,
  )
  const reversedTableData = filtered.slice().reverse()

  return (
    <div className="w-full">
      <div className="flex items-center justify-between mb-3">
        <h2 className="text-xl font-semibold text-gray-600 md:text-2xl">
          Wet And Dry Log Table
        </h2>

        <div className="flex justify-center rounded-md shadow-sm">
          <button
            type="button"
            aria-label="download results table data in csv format"
            className="inline-flex items-center p-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out border border-transparent rounded-md sm:px-3 sm:py-2 bg-secondary-800 hover:bg-secondary-700 focus:outline-none focus:border-secondary-900 focus:shadow-outline-secondary active:bg-secondary-900"
          >
            <svg
              className="-ml-0.5 mr-2 h-4 w-4"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
            </svg>

            <CSVLink
              className="text-white no-underline"
              filename={`Wet And Dry Log Table.csv`}
              data={csvData}
            >
              <span className="hidden text-white sm:inline-block">
                Download
              </span>{' '}
              <span className="text-white">CSV</span>
            </CSVLink>
          </button>
        </div>
      </div>

      <div className="flex flex-col my-4 sm:flex-row sm:justify-between sm:items-center">
        <div className="flex flex-col mt-2 text-sm lg:mt-0 lg:items-center lg:flex-row"></div>

        <div className="flex flex-col mt-2 text-sm lg:mt-0 lg:items-center lg:flex-row">
          <span className="mb-2 mr-3 font-bold tracking-wider text-gray-700 lg:mb-0">
            Events:
          </span>
          <div className="grid grid-cols-2 gap-1">
            <span className="inline-flex items-center justify-center px-2.5 py-0.5 rounded-md text-xs lg:text-sm font-bold text-yellow-900 bg-yellow-300">
              Dry
            </span>
            <span className="inline-flex items-center justify-center px-2.5 py-0.5 rounded-md text-xs lg:text-sm text-white bg-blue-500 font-bold">
              Wet
            </span>
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-4">
        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg overflow-y-auto max-h-128">
            <table className="min-w-full max-h-128">
              <thead>
                <tr>
                  <th className="sticky top-0 px-6 py-3 text-xs leading-4 tracking-wider text-center text-white border-r border-gray-200 bg-secondary-800">
                    Starting Date Time
                  </th>
                  <th className="sticky top-0 px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                    Ending Date Time
                  </th>
                  <th className="sticky top-0 px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                    Hours
                  </th>
                  <th className="sticky top-0 px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                    Avg Temp (˚F)
                  </th>
                  <th className="sticky top-0 px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                    Total Rain (in)
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {reversedTableData.map(day => {
                  return (
                    <tr key={day.startDateMs}>
                      <td className={`td py-2`}>
                        <span
                          className={`cell flex flex-col items-center ${dryWet(
                            day.event,
                          )}`}
                        >
                          {day.startDate}
                          {isFuture(day.startDateMs) && (
                            <span className=" text-xs text-center">
                              Forecast
                            </span>
                          )}
                        </span>
                      </td>

                      <td className={`td py-2`}>
                        <span
                          className={`cell flex flex-col items-center ${dryWet(
                            day.event,
                          )}`}
                        >
                          {day.endDate}
                          {isFuture(day.endDateMs) && (
                            <span className=" text-xs text-center">
                              Forecast
                            </span>
                          )}
                        </span>
                      </td>

                      <td className={`td py-2`}>
                        <span className={`cell ${dryWet(day.event)}`}>
                          {day.hours}
                        </span>
                      </td>

                      <td className={`td py-2`}>
                        <span className={`cell ${dryWet(day.event)}`}>
                          {day.event === 'dry' ? '-' : day.avgT}
                        </span>
                      </td>

                      <td className={`td py-2`}>
                        <span className={`cell ${dryWet(day.event)}`}>
                          {day.event === 'dry'
                            ? '-'
                            : day.prcpAmount.toFixed(2)}
                        </span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
