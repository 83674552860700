import React from 'react'
import parse from 'html-react-parser'

export default function ManagementGuide({
  management,
  cycle,
  greenTip,
  ascospore,
  isModelInSeason,
}) {
  let diseaseCycle = ''
  let diseaseManagement = ''
  if (!isModelInSeason) {
    diseaseCycle = management.outOfSeasonMsg.cycle
    diseaseManagement = management.outOfSeasonMsg.management
  } else {
    if (!greenTip) {
      diseaseCycle = management.beforeBiofix.cycle
      diseaseManagement = management.beforeBiofix.management
    } else {
      diseaseCycle = management.afterBiofix.cycle
      diseaseManagement = management.afterBiofix.management
    }

    if (ascospore) {
      diseaseCycle = management.afterAscosporeRelease.cycle
      diseaseManagement = management.afterAscosporeRelease.management
    }
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-secondary-800">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200"
                  >
                    Disease Cycle
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200"
                  >
                    Disease Management
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white">
                  <td className="px-6 py-4 text-sm text-gray-700">
                    {parse(diseaseCycle)}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-700">
                    {parse(diseaseManagement)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
