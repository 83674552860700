export const millsTable = {
  34: 41,
  35: 41,
  36: 35,
  37: 30,
  38: 30,
  39: 28,
  40: 28,
  41: 21,
  42: 21,
  43: 18,
  44: 18,
  45: 15,
  46: 13,
  47: 13,
  48: 12,
  49: 12,
  50: 11,
  51: 11,
  52: 9,
  53: 9,
  54: 8,
  55: 8,
  56: 8,
  57: 7,
  58: 7,
  59: 7,
  60: 7,
  61: 6,
  62: 6,
  63: 6,
  64: 6,
  65: 6,
  66: 6,
  67: 6,
  68: 6,
  69: 6,
  70: 6,
  71: 6,
  72: 6,
  73: 6,
  74: 6,
  75: 6,
  76: 6,
  77: 8,
  78: 8,
  79: 11,
}
