import { format, getDayOfYear } from "date-fns";
import React from "react";
import ModelLayout from "../../components/models/modelLayout";
import GlobalStateContext from "../../context/globalStateContext";
import { useAuth0 } from "../../context/react-auth0-spa";
import { modelInSeason, sdateEdate } from "../../utils/helpers";
import useFetchStationData from "../../utils/hooks/useFetchStationData";
import useStations from "../../utils/hooks/useStations";
import modelData from "./apple-scab.json";
import Acknowledgments from "./_acknowledgements";
import AscosporeMaturityGraph from "./_ascosporeMaturityGraph";
import AscosporeMaturitySummary from "./_ascosporeMaturitySummary";
import DateInput from "./_dateInput";
import InfectionEvents from "./_infectionEvents";
import InfectionEventsSummary from "./_infectionEventsSummary";
import ManagementGuide from "./_managementGuide";
import modelLogic from "./_modelLogic";
import MoreInfo from "./_moreInfo";
import References from "./_references";
import WeatherSummaryGraph from "./_weatherSummary";
import WetAndDryLogTable from "./_wetAndDryLogTable";

const visibleElementsArr = Object.entries(modelData.elements)
  .filter(([_, value]) => value.priority)
  .filter(([key, _]) => !key.includes("user"))
  .map((arr) => ({ ...arr[1], toggleName: arr[0] }))
  .sort((a, b) =>
    a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
  );

export default function AppleScab() {
  const h1Text = modelData.title;
  const { id, title, seasonStart, seasonEnd, elements } = modelData;
  const { userInputs, managementGuide } = elements;
  const { user, isAuthenticated } = useAuth0();
  const { stationList, station, setStation, favoriteStations, geoJSON } =
    useStations();
  const { dateOfInterest } = React.useContext(GlobalStateContext);
  const isModelInSeason = modelInSeason(dateOfInterest, modelData);

  const { sdate, edate } = sdateEdate(dateOfInterest, seasonEnd);
  const { isLoading, data } = useFetchStationData(station, sdate, edate);
  const [userGreenTipDate, setUserGreenTipDate] = React.useState(null);

  let mData = null;
  let view = "overWinter";
  if (data && isModelInSeason) {
    mData = modelLogic(data, dateOfInterest, userGreenTipDate);
    view = mData.view;
  }
  // console.log({mData})

  const [isMoreInfo, setIsMoreInfo] = React.useState(false);
  const [isAscosporeMaturity, setIsAscosporeMaturity] = React.useState(true);
  const [isAscosporeMaturityGraph, setIsAscosporeMaturityGraph] =
    React.useState(false);
  const [isWeatherSummaryGraph, setIsWeatherSummaryGraph] =
    React.useState(false);
  const [isWetDryLogTable, setIsWetDryLogTable] = React.useState(true);
  const [isInfectionEventSummary, setIsInfectionEventSummary] =
    React.useState(true);
  const [isManagementGuide, setIsManagementGuide] = React.useState(true);
  const [isInfectionEvents, setIsInfectionEvents] = React.useState(false);
  const isVisible = station && mData;
  const march1Doy = getDayOfYear(new Date(dateOfInterest.year, 2, 1));
  const [visibleElements, setVisibleElements] = React.useState([]);

  React.useEffect(() => {
    let filtered = [...visibleElementsArr];
    if (view === "overWinter") {
      filtered = visibleElementsArr.filter((d) => d.priority === 5);
    }
    if (view === "early") {
      filtered = visibleElementsArr.filter((d) => d.priority === 5);
    }
    if (view === "inSeason") {
      filtered = [...visibleElementsArr];
    }
    if (view === "late") {
      filtered = visibleElementsArr.filter((d) => d.priority !== 1);
    }
    setVisibleElements(filtered);
  }, [dateOfInterest, view]);

  React.useEffect(() => {
    setUserGreenTipDate(null);
  }, [station]);

  React.useEffect(() => {
    if (!user) return;

    if (Object.keys(user.activeTools)) {
      const model = user.activeTools.find((model) => model.id === id);
      if (model) {
        if (
          Object.keys(model.biofix1).includes(`${dateOfInterest.year}`) &&
          Object.keys(model.biofix1[dateOfInterest.year]).includes(station.id)
        ) {
          setUserGreenTipDate(model.biofix1[dateOfInterest.year][station.id]);
        } else {
          setUserGreenTipDate(null);
        }
      }
    }
  }, [station, id, user, dateOfInterest]);

  return (
    <ModelLayout
      isAuthenticated={isAuthenticated}
      stationList={user ? favoriteStations : stationList}
      station={station}
      setStation={setStation}
      allStations={stationList}
      geoJSON={geoJSON}
      data={data}
      isModelInSeason={isModelInSeason}
      isLoading={isLoading}
      modelData={modelData}
      visibleElements={visibleElements}
      titleSize={"text-lg lg:text-2xl"}
      moreInfo={<MoreInfo station={station} moreInfo={modelData.moreInfo} />}
      setIsMoreInfo={setIsMoreInfo}
      references={References}
      acknowledgments={Acknowledgments}
      isMoreInfo={isMoreInfo}
      showManagementGuide={isManagementGuide}
      setShowManagementGuide={setIsManagementGuide}
      showResultsTable={isAscosporeMaturity}
      setShowResultsTable={setIsAscosporeMaturity}
      showResultsTable2={isInfectionEventSummary}
      setShowResultsTable2={setIsInfectionEventSummary}
      showResultsTable3={isWetDryLogTable}
      setShowResultsTable3={setIsWetDryLogTable}
      showResultsTable4={isInfectionEvents}
      setShowResultsTable4={setIsInfectionEvents}
      showResultsChart={isAscosporeMaturityGraph}
      setShowResultsChart={setIsAscosporeMaturityGraph}
      showResultsChart2={isWeatherSummaryGraph}
      setShowResultsChart2={setIsWeatherSummaryGraph}
      tutorialLink={modelData.tutorialLink}
      h1Text={h1Text}
    >
      {!isLoading &&
        isVisible &&
        (mData.view === "early" ||
          mData.view === "inSeason" ||
          mData.view === "late") && (
          <div className="px-6 py-6 mb-12 bg-white rounded-lg shadow-lg">
            <>
              <p className="sm:mx-auto sm:max-w-lg">{mData.greenTipMsg}</p>
              <div className="pb-2 sm:mx-auto sm:max-w-lg">
                <DateInput
                  modelId={id}
                  id={"biofix1"}
                  dbKey="biofix1"
                  minDate={new Date(`${dateOfInterest.year}-${seasonStart}`)}
                  maxDate={dateOfInterest.date}
                  biofix={mData.greenTip}
                  userBiofix={userGreenTipDate}
                  setUserBiofix={setUserGreenTipDate}
                  label={userInputs.firstInput.label}
                  modelName={title}
                  tooltip={`Reset to default value`}
                  station={station}
                ></DateInput>
              </div>
              {!mData.greenTip && (
                <>
                  <p className="sm:mx-auto sm:max-w-lg">
                    Degree day accumulations estimate that greentip may not have
                    occurred yet. If it has, enter the actual date for blocks of
                    interest.
                  </p>

                  {mData.selectedDate && (
                    <p className="sm:mx-auto sm:max-w-lg">
                      Accumulated degree days (base 43°F) through{" "}
                      {format(mData.selectedDate.date, "M/d/yyyy")}:{" "}
                      <span className="font-extrabold">
                        {mData.selectedDate.gdd}
                      </span>
                    </p>
                  )}
                </>
              )}
            </>
          </div>
        )}

      {isVisible && isAscosporeMaturity && mData.view === "inSeason" && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <AscosporeMaturitySummary
            station={station}
            tableData={mData.ascosporeMaturitySummaryTable}
            csvData={mData.ascosporeMaturitySummaryCSV}
          ></AscosporeMaturitySummary>

          <p className="text-sm">{mData?.ascosporeMsg}</p>
        </div>
      )}

      {isVisible &&
        (mData.view === "inSeason" || mData.view === "late") &&
        isAscosporeMaturityGraph &&
        dateOfInterest.dayOfYear >= march1Doy && (
          <div className={`mb-16 sm:mb-18 md:mb-20`}>
            <AscosporeMaturityGraph
              title="Ascospore Maturity Graph"
              data={mData.ascosporeMaturitySummary}
              biofix1={mData.greenTip}
            ></AscosporeMaturityGraph>

            {mData?.dateWhenMaturityReached.ascosporeMaturity >= 95 && (
              <p className="text-sm">{elements.resultsGraph.message}</p>
            )}
          </div>
        )}

      {isVisible &&
        (mData.view === "inSeason" || mData.view === "late") &&
        isWeatherSummaryGraph &&
        dateOfInterest.dayOfYear >= march1Doy && (
          <div className={`mb-16 sm:mb-18 md:mb-20`}>
            <WeatherSummaryGraph
              title="Daily Weather Summary"
              data={mData.ascosporeMaturitySummary}
              biofix1={mData.greenTip}
            ></WeatherSummaryGraph>
          </div>
        )}

      {isVisible &&
        isInfectionEventSummary &&
        (mData.view === "inSeason" || mData.view === "late") && (
          <div className="mb-16 sm:mb-18 md:mb-20">
            <InfectionEventsSummary
              station={station}
              tableData={mData.infectionEventsSummaryTable}
              csvData={mData.infectionEventsSummaryCSV}
              dateOfInterest={dateOfInterest}
            ></InfectionEventsSummary>

            <p className="text-sm">
              Infection events, shown in red above, are based on the{" "}
              <a
                href="https://nysipm-newa-public-assets.s3.amazonaws.com/revised-mills-table-20210905.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Revised Mills Table
              </a>{" "}
              and are calculated beginning with 0.01 inch of rain. The word
              "Combined" means the wetting event on this day is being combined
              with another wetting event using the following rule: two
              successive wetting periods, the first started by rain, should be
              considered a single, uninterrupted wet period if the intervening
              dry period is less than 24 hours.{" "}
              <span className="font-bold">
                When an infection event is in the 5-day forecast, the actual
                weather data logged may or may not translate into an actual
                infection event. Therefore, the table output may change once
                actual weather data are logged.
              </span>
            </p>
          </div>
        )}

      {!isLoading && isManagementGuide && (
        <div className={`mb-16 sm:mb-18 md:mb-20`}>
          <h2 className="mb-3 font-semibold text-gray-600 sm:mb-5 md:mb-6 md:text-2xl">
            Management Guide
          </h2>
          <ManagementGuide
            cycle={mData && mData.view}
            management={managementGuide}
            greenTip={mData && mData.greenTip}
            ascospore={mData && mData.ascosporeAllReleasesDate}
            isModelInSeason={isModelInSeason}
          ></ManagementGuide>
        </div>
      )}

      {isVisible &&
        isWetDryLogTable &&
        (mData.view === "inSeason" || mData.view === "late") && (
          <div className="mb-16 sm:mb-18 md:mb-20">
            <WetAndDryLogTable
              // station={station}
              selectedDate={mData.selectedDate}
              tableData={mData.wetAndDryLogTable}
              csvData={mData.wetAndDryLogCSV}
            ></WetAndDryLogTable>
          </div>
        )}

      {isVisible &&
        isInfectionEvents &&
        (mData.view === "inSeason" || mData.view === "late") && (
          <div className="mb-16 sm:mb-18 md:mb-20">
            <InfectionEvents
              // station={station}
              selectedDate={mData.selectedDate}
              tableData={mData.combinedEventsWithInfections}
              csvData={mData.combinedEventsCSV}
            ></InfectionEvents>
          </div>
        )}
    </ModelLayout>
  );
}
