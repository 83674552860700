import {format, getDayOfYear} from 'date-fns'
import {baskervilleEmin, calculateGdd, simpleAve} from '../../utils/helpers'
import {millsTable} from './_.millsTable'
import model from './apple-scab.json'

const beforeBiofixMsg = dd =>
  `You are approximately ${dd} degree days (base 43F BE) from green tip.`

const after95PctAscosporeMaturity =
  ascosporeReleaseDate => `Primary scab season is over. Ascospores were essentially all released on 
${format(
  ascosporeReleaseDate,
  'MMM d',
)}. If you are unsure whether ascospores have been depleted in your orchard, enter your green tip date to recalculate ascospore maturity for your orchard.`

export default function appleScabLogic(data, dateOfInterest, userGreenTipDate) {
  const year = dateOfInterest.year
  const doy = dateOfInterest.dayOfYear
  const november1Doy = getDayOfYear(new Date(year, 10, 1))
  const december31Doy = getDayOfYear(new Date(year, 11, 31))
  const october31Doy = getDayOfYear(new Date(year, 9, 31))
  const todayDoy = getDayOfYear(new Date())
  const tomorrowDoy = todayDoy + 1

  // let endIdx = data.dailyData.length
  let dateOfInterestIdx = data.dailyData.findIndex(
    d => d.serverDate === dateOfInterest.server,
  )

  // if (year !== new Date().getFullYear()) {
  // if (dateOfInterestIdx > -1) endIdx = dateOfInterestIdx + 1
  // }
  const dailyData = data.dailyData.slice(0, dateOfInterestIdx + 6)
  // console.log({dailyData})

  ////////////////////////////////////////////////////////////////////////////
  const dailyDataWithGdd43BE = calculateGdd(
    dailyData,
    43,
    0,
    baskervilleEmin,
  ).map(day => {
    return {...day, hasGreenTip: day.gdd >= 101 ? true : false}
  })
  // console.log({dailyDataWithGdd43BE})

  let selectedDate = dailyDataWithGdd43BE.find(
    d => d.dayOfYear === dateOfInterest.dayOfYear,
  )
  // console.log({selectedDate})

  let greenTip = null
  if (selectedDate) {
    if (selectedDate.hasGreenTip) {
      greenTip = dailyDataWithGdd43BE.find(d => d.hasGreenTip)
    }
    if (userGreenTipDate) {
      greenTip = dailyDataWithGdd43BE.find(
        d => d.dateDisplay === userGreenTipDate,
      )
    }
  }
  // console.log({greenTip})

  let view = ''
  if (doy >= november1Doy && doy <= december31Doy) {
    view = 'overWinter'
  }
  // console.log({view})
  //////////////////////////////////////////////////////////////////////////////
  const infectionEventsSummary = dailyDataWithGdd43BE.map((d, i) => {
    const temps = d.lwet
      .map((lw, i) => {
        if (lw > 0) {
          return d.temp[i]
        } else {
          return null
        }
      })
      .filter(t => typeof t === 'number')

    let avgTWetHours = '-'
    if (temps.length !== 0) {
      avgTWetHours = (
        temps.reduce((acc, val) => acc + val, 0) / temps.length
      ).toFixed(0)
    }

    const lwetHours = d.lwet.filter(d => d > 0).length
    const rhHoursAbove89 = d.rhum.filter(d => d >= 90).length

    let prcpSum = d.prcp.filter(d => d > 0).reduce((acc, val) => acc + val, 0)
    if (dateOfInterest.isCurrentYear) {
      if (d.dayOfYear === todayDoy) {
        const qpfs = d.qpf.filter(Boolean).reduce((acc, val) => acc + val, 0)
        prcpSum = prcpSum + qpfs
      }
      if (d.dayOfYear === tomorrowDoy) {
        const qpfs = d.qpf.filter(Boolean).reduce((acc, val) => acc + val, 0)
        prcpSum = qpfs
      }
    }
    let pop10pm = 0
    let pop10am = 0
    if (i > 0 && d.pop12) {
      pop10pm = dailyDataWithGdd43BE[i - 1].pop12[21]
      pop10am = d.pop12[9]
    }

    return {
      date: d.date,
      ms: d.ms,
      dayOfYear: d.dayOfYear,
      dwpt: d.dwpt,
      isForecast: d.isForecast,
      temp: d.temp,
      rhum: d.rhum,
      pop12: d.pop12,
      maxt: d.maxt,
      mint: d.mint,
      prcp: d.prcp,
      lwet: d.lwet,
      avgTWetHours,
      lwetHours,
      rhHoursAbove89,
      prcpSum: +prcpSum.toFixed(2),
      pop10pm,
      pop10am,
      infection: 'no',
      dateDisplay: d.dateDisplay,
    }
  })
  // console.log({infectionEventsSummary})

  //////////////////////////////////////////////////////////////////////////////
  let ascosporeStartIdx = 0
  if (greenTip) {
    const idx = greenTip.dayOfYear - 9
    if (idx > 0) {
      ascosporeStartIdx = idx
    }
  }
  // console.log({ascosporeStartIdx})

  // Ascospore Maturity Summary ------------------------------------------------
  const dailyDataWithGdd0C = calculateGdd(
    dailyData,
    0,
    ascosporeStartIdx,
    simpleAve,
  ).map(day => {
    if (day.isForecast) {
      let dayPlus = {...day}
      let prcpForecast = []
      for (let i = 0; i < dayPlus.prcp.length; i++) {
        const prcp = dayPlus.prcp[i]
        if (typeof prcp === 'number') {
          prcpForecast.push(prcp)
        } else {
          const qpf = dayPlus.qpf[i]
          if (typeof qpf === 'number') {
            prcpForecast.push(qpf)
          } else {
            const pop12 = dayPlus.pop12[i]
            if (typeof pop12 === 'number' && pop12 >= 60) {
              prcpForecast.push(0.02)
            } else {
              prcpForecast.push(0)
            }
          }
        }
      }
      dayPlus['prcpForecast'] = prcpForecast
      const prcpsForecast = prcpForecast.filter(d => typeof d === 'number')
      let prcpForecastSum = 0
      if (prcpsForecast.length > 0) {
        prcpForecastSum = prcpsForecast.reduce((acc, val) => acc + val, 0)
      }
      dayPlus['prcpForecastSum'] = prcpForecastSum
      dayPlus['prcpCumulative'] = prcpForecastSum
      return dayPlus
    }
    return day
  })
  // console.log({dailyDataWithGdd0C})

  let prcpCumulative7Days = []
  let gdd0C = 0
  let accDeplete = 0
  const ascosporeMaturitySummary = dailyDataWithGdd0C.map((day, i) => {
    prcpCumulative7Days.push(day.prcpCumulative)

    if (i >= 6) {
      const prcp7Days = prcpCumulative7Days
        .slice(i - 7, i)
        .filter(d => typeof d === 'number')
        .reduce((acc, val) => acc + val, 0)

      if (prcp7Days > 0) {
        gdd0C += day.dd
      }
    }

    const comp = Math.exp((Math.PI / Math.sqrt(3)) * (2.51 + 0.01 * gdd0C - 5))
    const ascosporeMaturity = (100 * comp) / (1 + comp)
    const comp2 = Math.exp((Math.PI / Math.sqrt(3)) * (-1.676 + 0.01 * gdd0C))
    const error = 100 * (comp2 / (1 + comp2)) - ascosporeMaturity
    const lowerCI =
      ascosporeMaturity - error < 0 ? 0 : ascosporeMaturity - error
    const upperCI = ascosporeMaturity + error

    const confidenceInterval = [lowerCI.toFixed(0), upperCI.toFixed(0)]

    let maturity95Reached = false
    if (Number(Math.round(ascosporeMaturity)) >= 95) maturity95Reached = true

    let dailyAscosporeDischarge
    let cumulativeAscosporeDischarge
    let ascosporeAllReleasedDate = false
    let dayPrcpSum = []
    if (i > 0) {
      let nightPrcp = []
      if (day.isForecast === false) {
        nightPrcp = [
          ...dailyDataWithGdd0C[i - 1].prcp.slice(19),
          ...day.prcp.slice(0, 6),
        ]
      }
      if (day.isForecast === true) {
        const isPrcpForecast = dailyDataWithGdd0C[i - 1].prcpForecast
        if (isPrcpForecast) {
          nightPrcp = [
            ...dailyDataWithGdd0C[i - 1].prcpForecast.slice(19),
            ...day.prcpForecast.slice(0, 6),
          ]
        } else {
          nightPrcp = [
            ...dailyDataWithGdd0C[i - 1].prcp.slice(19),
            ...day.prcp.slice(0, 6),
          ]
        }
      }

      let dayPrcp = []
      if (day.isForecast === false) {
        dayPrcp = day.prcp.slice(6, 19)
      }
      if (day.isForecast === true) {
        dayPrcp = day.prcpForecast.slice(6, 19)
      }

      const nightPrcpSum = nightPrcp
        .filter(Boolean)
        .reduce((acc, val) => acc + val, 0)
      dayPrcpSum = dayPrcp.filter(Boolean).reduce((acc, val) => acc + val, 0)

      const dayTemp = day.temp.slice(6, 19)
      // console.log({ nightTemp, dayTemp })

      const dayTemps = dayPrcp
        .map((d, i) => (d > 0 ? dayTemp[i] : null))
        .filter(d => typeof d === 'number')

      let avgDaytimeTemp = 0
      if (dayTemps.length !== 0) {
        avgDaytimeTemp =
          dayTemps.reduce((acc, val) => acc + val, 0) / dayTemps.length
      }
      // console.log({ dayTemps, avgDaytimeTemp })

      let nightTimeDepletion = 0
      if (nightPrcpSum > 0) {
        nightTimeDepletion = (ascosporeMaturity - accDeplete) * 0.05
        accDeplete += nightTimeDepletion
      }

      let dayTimeDepletion = 0
      let depletePercentage = 0
      if (dayPrcpSum > 0) {
        if (avgDaytimeTemp >= 50 && dayPrcpSum >= 0.1) {
          depletePercentage = 0.9
        }
        if (avgDaytimeTemp >= 50 && dayPrcpSum < 0.1) {
          depletePercentage = 0.5
        }
        if (avgDaytimeTemp < 50 && dayPrcpSum >= 0.1) {
          depletePercentage = 0.5
        }
        if (avgDaytimeTemp < 50 && dayPrcpSum < 0.1) {
          depletePercentage = 0.25
        }

        dayTimeDepletion = (ascosporeMaturity - accDeplete) * depletePercentage
        accDeplete += dayTimeDepletion
      }

      const dayNightDepletionSum = nightTimeDepletion + dayTimeDepletion

      if (dayNightDepletionSum > 0 && dayNightDepletionSum < 1) {
        dailyAscosporeDischarge = '<1%'
      } else {
        dailyAscosporeDischarge = `${dayNightDepletionSum.toFixed(0)}%`
      }

      if (accDeplete > 0 && accDeplete < 1) {
        cumulativeAscosporeDischarge = '<1%'
      } else {
        cumulativeAscosporeDischarge = `${accDeplete.toFixed(0)}%`
      }

      if (ascosporeMaturity >= 95 && dayPrcpSum > 0.1 && avgDaytimeTemp > 50) {
        ascosporeAllReleasedDate = true
      }
    }

    const maturity = Number(Math.round(ascosporeMaturity))

    const mint = day.mint
    const maxt = day.maxt
    return {
      date: day.date,
      dateDisplay: format(day.date, 'yyyy-MM-dd'),
      isForecast: day.isForecast,
      dayOfYear: day.dayOfYear,
      ms: day.ms,
      prcpCumulative: day.prcpCumulative,
      mint,
      maxt,
      maxtMinusMint: maxt - mint,
      mintMaxt: `${mint} / ${maxt}`,
      maturity95Reached,
      ascosporeMaturity: maturity,
      dailyAscosporeDischarge,
      cumulativeAscosporeDischarge,
      ascosporeAllReleasedDate,
      confidenceInterval,
      dayPrcpSum,
    }
  })
  // console.log({ascosporeMaturitySummary})

  const dateWhenMaturityReached = ascosporeMaturitySummary.find(
    d => d.dateDisplay === dateOfInterest.server,
  )
  // console.log({dateWhenMaturityReached})

  let greenTipMsg = ''
  let ascosporeMsg = ''
  let ascosporeAllReleasesDate = null
  if (doy >= 1 && greenTip === null) {
    view = 'early'
    greenTipMsg = beforeBiofixMsg(101 - selectedDate.gdd)
  }
  if (greenTip) {
    if (doy >= greenTip.dayOfYear && doy <= october31Doy) {
      view = 'inSeason'
      const found = ascosporeMaturitySummary.find(
        d => d.dateDisplay === dateOfInterest.server,
      )

      greenTipMsg = model.elements.userInputs.firstInput.messages.afterBiofix

      if (found?.ascosporeMaturity >= 95) {
        ascosporeMsg = model.elements.resultsTable.message

        const selectedDateIdx = ascosporeMaturitySummary.findIndex(
          d => d.dateDisplay === dateOfInterest.server,
        )

        let foundIdx = ascosporeMaturitySummary.findIndex(
          d => d.ascosporeAllReleasedDate === true,
        )
        if (found.ascosporeAllReleasedDate) {
          ascosporeMsg = `Ascospore were all released on ${format(
            found.date,
            'MMM d',
          )}. Orchards may still be at risk for secondary, conidial infections.`
        }

        if (foundIdx > -1) {
          if (selectedDateIdx >= foundIdx + 1) {
            view = 'late'
            const ascosporeAllReleasesDate = ascosporeMaturitySummary.find(
              d => d.ascosporeAllReleasedDate,
            )
            if (ascosporeAllReleasesDate) {
              greenTipMsg = after95PctAscosporeMaturity(
                ascosporeAllReleasesDate.date,
              )
            }
            ascosporeMsg = ''
          }
        }
      }
    }
  }
  // console.log({greenTipMsg})
  // console.log({ascosporeMsg})
  // console.log({ascosporeAllReleasesDate})
  // console.log({view})

  // Ascospore Maturity Summary Table //////////////////////////////////////////
  let ascosporeMaturitySummaryTable = [...ascosporeMaturitySummary]
  if (ascosporeMaturitySummary.length >= 8) {
    if (dateOfInterestIdx !== -1) {
      const startIdx = ascosporeMaturitySummary.findIndex(
        d => d.dateDisplay === dateOfInterest.server,
      )
      ascosporeMaturitySummaryTable = ascosporeMaturitySummary.slice(
        startIdx - 2,
        startIdx + 6,
      )
    }
  }

  const ascosporeMaturitySummaryCSV = ascosporeMaturitySummary.map(d => {
    return {
      date: format(d.date, 'MM/dd/yyyy'),
      'Ascospore Maturity': d.ascosporeMaturity,
      'Daily Ascospore Discharge': d.dailyAscosporeDischarge,
      'Cumulative Ascospore Discharge': d.cumulativeAscosporeDischarge,
    }
  })

  /////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////
  let oneHour = 3600000 // in milliseconds
  let wetEvents = []
  let wetEvent = []
  let dryEvents = []
  let dryEvent = []
  let greenTipIdx = -1
  if (greenTip) {
    greenTipIdx = data.hourlyData.findIndex(d => d.ms === greenTip?.ms)
  }

  if (greenTipIdx !== -1) {
    if ('lwet' in data.hourlyData[0] && 'prcp' in data.hourlyData[0]) {
      let allData = []
      if (dateOfInterest.isCurrentYear) {
        const hourlyFcstData = data.hourlyFcstData.map(hour => {
          if (hour.isForecast) {
            let prcpF = 0
            if (typeof hour.qpf === 'number') {
              prcpF = hour.qpf
            } else {
              if (hour.pop12 >= 60) {
                prcpF = 0.02
              }
            }
            return {...hour, prcp: prcpF}
          }
          return hour
        })

        allData = [...data.hourlyData, ...hourlyFcstData].slice(
          greenTipIdx - 23,
        )
      } else {
        allData = data.hourlyData.slice(greenTipIdx - 23)
      }
      // console.log({allData})

      // Determine wet events
      for (let index = 0; index < allData.length; index++) {
        const {lwet, prcp, ms, temp, date} = allData[index]
        let ciccio = {
          index,
          ms,
          temp,
          prcp,
          lwet,
          date,
        }
        if (wetEvent.length === 0) {
          if (lwet > 0 && prcp > 0) {
            wetEvent.push(ciccio)
          }
        } else {
          if (lwet > 0 || prcp > 0) {
            wetEvent.push(ciccio)
            if (index === allData.length - 1) {
              wetEvents.push(wetEvent)
              wetEvent = []
            }
          }
          if (lwet === 0 && prcp === 0) {
            wetEvents.push(wetEvent)
            wetEvent = []
          }
        }
      }
      // Determine dry events
      for (let index = 0; index < allData.length; index++) {
        const {lwet, prcp, ms, temp, date} = allData[index]
        let ciccio = {
          index,
          ms,
          temp,
          prcp,
          lwet,
          date,
        }
        if (dryEvent.length === 0) {
          if (lwet === 0 && prcp === 0) {
            dryEvent.push(ciccio)
          }
        } else {
          if (lwet > 0 && prcp > 0) {
            dryEvents.push(dryEvent)
            dryEvent = []
          } else {
            dryEvent.push(ciccio)
            if (index === allData.length - 1) {
              dryEvents.push(dryEvent)
              dryEvent = []
            }
          }
        }
      }
    }
  }
  // console.log({dryEvents})

  const wetEventsSummary = wetEvents.map(event => {
    const temps = event.map(d => d.temp).filter(t => t !== 'M')
    const avgT = temps.reduce((acc, val) => acc + val, 0) / temps.length
    const hours = event.length
    const prcps = event
      .map(d => d.prcp)
      .filter(d => d !== 'M')
      .filter(Boolean)
    const prcpAmount = prcps.reduce((acc, val) => acc + val, 0)

    return {
      event: 'wet',
      startDate: format(event[0].ms - oneHour, 'MMM dd h:01 aaa'),
      startDateMs: event[0].ms,
      endDate: format(event.slice(-1)[0].ms, 'MMM dd h:00 aaa'),
      endDateMs: event.slice(-1)[0].ms,
      hours,
      avgT: +avgT.toFixed(0),
      prcpAmount: +prcpAmount.toFixed(2),
    }
  })
  // console.log({ wetEventsSummary })

  const dryEventsSummary = dryEvents.map(event => {
    const temps = event.map(d => d.temp).filter(t => t !== 'M')
    const avgT = temps.reduce((acc, val) => acc + val, 0) / temps.length
    const hours = event.length
    const prcps = event
      .map(d => d.prcp)
      .filter(d => d !== 'M')
      .filter(Boolean)
    const prcpAmount = prcps.reduce((acc, val) => acc + val, 0)

    return {
      event: 'dry',
      startDate: format(event[0].ms - oneHour, 'MMM dd h:01 aaa'),
      startDateMs: event[0].ms,
      endDate: format(event.slice(-1)[0].ms, 'MMM dd h:00 aaa'),
      endDateMs: event.slice(-1)[0].ms,
      hours,
      avgT: +avgT.toFixed(0),
      prcpAmount: +prcpAmount.toFixed(2),
    }
  })
  // console.log({dryEventsSummary})

  let wetAndDryLogTable = [...dryEventsSummary, ...wetEventsSummary].sort(
    (a, b) => (a.startDateMs > b.startDateMs ? 1 : -1),
  )
  // console.log({ wetAndDryLogTable })

  const wetAndDryLogCSV = wetAndDryLogTable.map(d => {
    return {
      'Starting Date Time': d.startDate,
      'Ending Date Time': d.endDate,
      Hours: d.hours,
      'Avg Temp (˚F)': d.avgT,
      'Total Rain (in)': d.prcpAmount,
    }
  })

  let combinedEvents = []

  for (let index = 0; index < wetEventsSummary.length; index++) {
    const wetEvent = wetEventsSummary[index]
    if (index === 0) {
      combinedEvents.push(wetEvent)
    } else {
      let prevEvent = {...combinedEvents[combinedEvents.length - 1]}
      const isLessThan24 =
        (wetEvent.startDateMs - prevEvent.endDateMs) / (1000 * 60 * 60) < 24
      if (isLessThan24) {
        const weightedAvgT = Number(
          (
            (prevEvent.hours * prevEvent.avgT +
              wetEvent.hours * wetEvent.avgT) /
            (prevEvent.hours + wetEvent.hours)
          ).toFixed(0),
        )

        prevEvent.event = 'combined'
        prevEvent.endDate = wetEvent.endDate
        prevEvent.endDateMs = wetEvent.endDateMs
        prevEvent.avgT = weightedAvgT
        prevEvent.hours = prevEvent.hours + wetEvent.hours
        prevEvent.prcpAmount = prevEvent.prcpAmount + wetEvent.prcpAmount
        combinedEvents[combinedEvents.length - 1] = prevEvent
      } else {
        combinedEvents.push(wetEvent)
      }
    }
  }
  // console.log({combinedEvents})

  const combinedEventsWithInfections = combinedEvents
    .map(e => {
      const {avgT, hours, startDateMs, endDateMs} = e
      let infection = 'no'
      if (avgT >= 34 && avgT <= 79) {
        const requiredHours = millsTable[avgT]
        if (hours >= requiredHours) {
          infection = 'yes'
        }
      }
      return {
        ...e,
        infection,
        startDateDoy: getDayOfYear(startDateMs),
        endDateDoy: getDayOfYear(endDateMs),
      }
    })
    .filter(e => e.infection === 'yes')

  // console.log({combinedEventsWithInfections})

  const combinedEventsCSV = combinedEventsWithInfections.map(e => {
    return {
      'Starting Date Time': e.startDate,
      'Ending Date Time': e.endDate,
      Hours: e.hours,
      'Avg Temp (˚F)': e.avgT,
      'Total Rain (in)': e.prcpAmount.toFixed(2),
      Combined: e.event === 'combined' ? 'combined' : 'single',
    }
  })

  let infectionEventsSummaryTable = [...infectionEventsSummary]
  if (infectionEventsSummary.length >= 8) {
    const startIndex = infectionEventsSummary.findIndex(
      d => d.dateDisplay === dateOfInterest.server,
    )
    infectionEventsSummaryTable = infectionEventsSummary
      .slice(startIndex - 2, startIndex + 6)
      .map(d => {
        const {dayOfYear} = d
        const event = combinedEventsWithInfections.find(
          e => dayOfYear >= e.startDateDoy && dayOfYear <= e.endDateDoy,
        )

        if (event) {
          if (event.endDateDoy === dayOfYear) {
            return {...d, infection: 'yes'}
          }
          if (dayOfYear >= event.startDateDoy && dayOfYear < event.endDateDoy) {
            return {...d, infection: 'combined'}
          }
        }

        return d
      })
  }

  const infectionEventsSummaryCSV = infectionEventsSummary.map(d => {
    const {dayOfYear} = d
    const event = combinedEventsWithInfections.find(
      e => dayOfYear >= e.startDateDoy && dayOfYear <= e.endDateDoy,
    )

    let infection = 'no'
    if (event) {
      if (event.endDateDoy === dayOfYear) {
        infection = 'yes'
      }
      if (dayOfYear >= event.startDateDoy && dayOfYear < event.endDateDoy) {
        infection = 'combined'
      }
    }

    return {
      date: format(d.date, 'MM/dd/yyyy'),
      'Infection Events': infection,
      'Avg Temp (˚F) for wet hours': d.avgTWetHours,
      'Leaf Wetness (hrs)': d.lwetHours,
      'Hrs > 90% RH': d.rhHoursAbove89,
      'Rain Amount (in)': d.prcpSum,
    }
  })

  return {
    ascosporeMaturitySummary,
    ascosporeMaturitySummaryTable,
    ascosporeMaturitySummaryCSV,
    ascosporeAllReleasesDate,
    selectedDate,
    view,
    greenTip,
    greenTipMsg,
    ascosporeMsg,
    infectionEventsSummaryTable,
    infectionEventsSummaryCSV,
    wetAndDryLogTable,
    wetAndDryLogCSV,
    combinedEvents,
    combinedEventsCSV,
    combinedEventsWithInfections,
    dateWhenMaturityReached,
  }
}
