import React from 'react'

export default function Acknowledgements() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        This disease forecasting model was co-authored and developed in
        collaboration with <a href="mailto:kdc33@cornell.edu">Dr. Kerik Cox</a>{' '}
        in the Department of Plant Pathology and Plant-Microbe Biology at
        Cornell University in Geneva, New York. Please contact Dr. Cox with any
        questions regarding the scientific content and recommendations delivered
        in model outputs.
      </p>
    </>
  )
}
