import {format, getDayOfYear} from 'date-fns'
import React from 'react'
import {CSVLink} from 'react-csv'

const todayDoy = getDayOfYear(new Date())
export default function ResultsTable({tableData, csvData, dateOfInterest}) {
  if (!tableData) return null

  return (
    <div className="w-full">
      <div className="flex items-center justify-between mb-3">
        <h2 className="text-xl font-semibold text-gray-600 md:text-2xl">
          Infection Events Summary
        </h2>

        <div className="flex justify-center rounded-md shadow-sm">
          <button
            type="button"
            aria-label="download results table data in csv format"
            className="inline-flex items-center p-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out border border-transparent rounded-md sm:px-3 sm:py-2 bg-secondary-800 hover:bg-secondary-700 focus:outline-none focus:border-secondary-900 focus:shadow-outline-secondary active:bg-secondary-900"
          >
            <svg
              className="-ml-0.5 mr-2 h-4 w-4"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
            </svg>

            <CSVLink
              className="text-white no-underline"
              filename={`Infection Event Summary.csv`}
              data={csvData}
            >
              <span className="hidden text-white sm:inline-block">
                Download
              </span>{' '}
              <span className="text-white">CSV</span>
            </CSVLink>
          </button>
        </div>
      </div>

      <div className="flex flex-col my-4 sm:flex-row sm:justify-between sm:items-center">
        <div className="flex flex-col mt-2 text-sm lg:mt-0 lg:items-center lg:flex-row"></div>

        <div className="flex flex-col mt-2 text-sm lg:mt-0 lg:items-center lg:flex-row">
          <span className="mb-2 mr-3 font-bold tracking-wider text-gray-700 lg:mb-0">
            Events:
          </span>
          <div className="grid grid-cols-2 gap-1">
            <span className="inline-flex items-center justify-center px-2.5 py-0.5 rounded-md text-xs lg:text-sm font-bold text-yellow-900 bg-yellow-300">
              Dry
            </span>
            <span className="inline-flex items-center justify-center px-2.5 py-0.5 rounded-md text-xs lg:text-sm text-white bg-blue-500 font-bold">
              Wet
            </span>
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-4">
        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-center text-white border-r border-gray-200 bg-secondary-800">
                    Date <small>({dateOfInterest.year})</small>
                  </th>
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                    Infection Events
                  </th>
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                    Average Temp (˚F) for wet hours
                  </th>
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                    Leaf Wetness (hours)
                  </th>
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                    Hours &gt; 90% RH
                  </th>
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                    Rain Amount
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {tableData.map((day, i) => {
                  return (
                    <tr
                      key={day.ms}
                      className={
                        i === 2 ? `font-extrabold text-center` : `text-center`
                      }
                    >
                      <td className={`td h-14`}>
                        <span className={`flex flex-col items-center cell`}>
                          {format(day.date, 'LLL d')}
                          <small className="font-bold text-gray-500">
                            {day.isForecast ? 'Forecast' : ''}
                          </small>
                        </span>
                      </td>

                      <td className={`td`}>
                        <span
                          className={`cell ${
                            day.infection === 'yes' ||
                            day.infection === 'combined'
                              ? `text-white bg-red-500`
                              : `text-green-900 bg-green-300`
                          }`}
                        >
                          {day.infection}
                        </span>
                      </td>

                      <td className={`td`}>
                        <span className={`cell`}>{day.avgTWetHours}</span>
                      </td>

                      <td className={`td`}>
                        <span
                          className={`cell ${
                            day.lwetHours > 0
                              ? `text-white bg-blue-500`
                              : `text-yellow-900 bg-yellow-300`
                          }`}
                        >
                          {day.lwetHours}
                        </span>
                      </td>

                      <td className={`td`}>
                        <span
                          className={`cell ${
                            day.rhHoursAbove89 > 0
                              ? `text-white bg-blue-500`
                              : `text-yellow-900 bg-yellow-300`
                          }`}
                        >
                          {day.rhHoursAbove89}
                        </span>
                      </td>

                      {dateOfInterest.isCurrentYear === false ||
                      day.dayOfYear < todayDoy + 2 ? (
                        <td className={`td`}>
                          <span
                            className={`cell ${
                              day.prcpSum > 0
                                ? `text-white bg-blue-500`
                                : `text-yellow-900 bg-yellow-300`
                            }`}
                          >
                            {day.prcpSum}
                          </span>
                        </td>
                      ) : (
                        <td className={`td`}>
                          <span
                            className={`cell ${
                              (day.pop10pm || day.pop10am) > 50
                                ? `text-white bg-blue-500`
                                : `text-yellow-900 bg-yellow-300`
                            }`}
                          >
                            <span className="grid grid-rows-2 gap-2">
                              <span>Night: {day.pop10pm}%</span>
                              <span>Day: {day.pop10am}%</span>
                            </span>
                          </span>
                        </td>
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
