import React from 'react'

export default function References() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        Becker, C.M., and Burr, T.J. 1994. Discontinuous wetting and survival of
        conidia of Venturia inaequalis on apple leaves. Phytopathology
        84:372-378.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Gadoury, D.M., and MacHardy, W.E. 1982. A model to estimate the maturity
        of ascospores of Venturia inaequalis. Phytopathology 72:901-904.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        MacHardy, W.E., and Gadoury, D.M. 1989. A revision of Mills' criteria
        for predicting apple scab infection periods. Phytopathology 79:304-310;
        Erratum, 81:809.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Stensvand, A., Eikemo, H., Gadoury, D.M., and Seem, R.C. 2005. Use of
        rainfall frequency threshold to adjust a degree-day model of ascospore
        maturity of Venturia inaequalis. Plant Dis. 89:198-202.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Stensvand, A., Gadoury, D.M., Amundsen, T., Semb, L., and Seem, R.C.
        1997. Ascospore release and infection of apple leaves by conidia and
        ascospores of Venturia inaequalis at low temperatures. Phytopathology
        87:1046-1053.
      </p>
    </>
  )
}
